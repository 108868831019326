import React from "react";
import AboutTheAuthors from "../About/AboutTheAuthors";

function About() {
  return (
    <div>
      <div className="container padFooter">
        <AboutTheAuthors />
      </div>
    </div>
  );
}

export default About;
