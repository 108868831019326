import React from "react";
import { NavLink } from "react-router-dom";

function Activity({
  name,
  description,
  link,
  photo,
  maxSize,
  primaryColor,
  secondaryColor,
  action,
}) {
  return (
    <div className="mt-4">
      <div className="container border border-5 rounded border-top-0 border-start-0">
        <div
          className="row text-center text-white bg-dark"
          style={{
            fontFamily: "comic sans ms",
          }}
        >
          <h4>{name}</h4>
        </div>
        <div className="row">
          {/* <div className="bookCoverContainer d-flex justify-contents-center"> */}
          <div className="text-center">
            <h6>{description}</h6>
          </div>
        </div>
        <div className="row pb-2 text-center">
          <div className="col-sm-1 col-md-2 col-lg-4"></div>
          <div className="col-sm-10 col-md-8 col-lg-4">
            <img
              className="img-fluid rounded"
              src={photo}
              style={{ maxHeight: maxSize }}
            ></img>
          </div>
          <div className="col-sm-1 col-md-2 col-lg-4"></div>
        </div>
        <div className="row pb-2">
          <div className="text-center">
            {action === "Play" ? (
              <NavLink
                to={link}
                className="btn btn-primary"
                style={{
                  fontFamily: "comic sans ms",
                  backgroundColor: primaryColor,
                  borderColor: secondaryColor,
                }}
              >
                {action}
              </NavLink>
            ) : (
              <a
                href={link}
                className="btn text-white"
                style={{
                  fontFamily: "comic sans ms",
                  backgroundColor: primaryColor,
                  borderColor: secondaryColor,
                }}
                download="Skunkerton Coloring Page"
              >
                {action}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Activity;
