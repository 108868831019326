import React from "react";
import sarahAndMichaelPhoto from "../../../images/SarahAndMichael.jpg";

function AboutTheAuthors() {
  return (
    <div>
      <div className="container mt-4 border border-5 rounded border-top-0 border-start-0">
        <div
          className="row text-center text-white bg-dark"
          style={{
            fontFamily: "comic sans ms",
            //backgroundColor: "#02a0b8",
          }}
        >
          <h4>About the authors</h4>
        </div>
        <div className="row mt-2">
          <div className="col-lg-3 col-md-4 col-sm-12">
            <img src={sarahAndMichaelPhoto} className="img-fluid rounded"></img>
          </div>
          <div className="col-lg-9 col-md-8 col-sm-12">
            <p>
              <span
                style={{
                  color: "#6ccada",
                  fontWeight: "bold",
                  fontFamily: "comic sans ms",
                }}
              >
                We're authors... we think?!?
              </span>
              &nbsp;&nbsp;Michael had the idea to write a book as a
              hobby.&nbsp;&nbsp;Sarah had the perfect story to turn into a
              children's book.&nbsp;&nbsp;
              <span
                style={{
                  color: "#f48a6c",
                  fontWeight: "bold",
                  fontFamily: "comic sans ms",
                }}
              >
                A true peanut butter and jelly moment!
              </span>
              &nbsp;&nbsp;Or maybe chocolate and peanut
              butter?&nbsp;&nbsp;Whatever best describes the perfect pairing...
              but I'm pretty sure it involves peanut
              butter.&nbsp;&nbsp;Regardless, we wrote a book, so yes, I suppose
              we are authors!
            </p>

            <p>
              <span
                style={{
                  color: "#ea5a42",
                  fontWeight: "bold",
                  fontFamily: "comic sans ms",
                }}
              >
                <em>The Skunk Who Couldn't Smell</em>
              </span>{" "}
              is our first book.&nbsp;&nbsp;We've had a ton of fun going through
              this process.&nbsp;&nbsp;So much so, that we plan to create more
              stories about the Skunkerton family in the future.&nbsp;&nbsp;
              <span
                style={{
                  color: "#70bf54",
                  fontWeight: "bold",
                  fontFamily: "comic sans ms",
                }}
              >
                We hope you enjoy reading and sharing our stories with your
                friends and family as much as we've enjoyed sharing them with
                you!
              </span>
            </p>

            <p>
              Thank you for{" "}
              <span
                style={{
                  color: "#e49f15",
                  fontWeight: "bold",
                  fontFamily: "comic sans ms",
                }}
              >
                all your support!
              </span>{" "}
              {/* support! */}
            </p>

            <p>
              <span
                style={{
                  fontWeight: "bold",
                  fontFamily: "comic sans ms",
                }}
              >
                XOXO
              </span>
            </p>

            <p>
              <span
                style={{
                  color: "#6ccada",
                  fontWeight: "bold",
                  fontFamily: "comic sans ms",
                }}
              >
                Sarah & Michael
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutTheAuthors;
