import React from "react";
import { useState } from "react";
import MiniLoader from "../../Reusables/MiniLoader";
import inputHelper from "../../Reusables/inputHelper";
import mainUrl from "../../Reusables/url";

function ContactUs() {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const url = `${mainUrl}ContactUs`;
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(userInput),
      headers: { "Content-type": "application/json; charset=UTF-8" },
    };

    const response = await fetch(url, requestOptions);
    const responseData = await response.json();

    if (responseData.isSuccess) {
      setLoading(false);
      const firstName = document.getElementById("firstName");
      firstName.value = "";
      const lastName = document.getElementById("lastName");
      lastName.value = "";
      const email = document.getElementById("email");
      email.value = "";
      const message = document.getElementById("message");
      message.value = "";
      document.getElementById("contactUsModal").showModal();
      const closeModal = document.getElementById("closeModal");
      closeModal.addEventListener("click", () => {
        document.getElementById("contactUsModal").close();
      });
    }
  };

  const initialUserData = {
    FirstName: "",
    LastName: "",
    Email: "",
    Message: "",
  };

  const [userInput, setUserInput] = useState(initialUserData);
  const handleUserInput = (e) => {
    const tempData = inputHelper(e, userInput);
    setUserInput(tempData);
  };

  return (
    <div className="container padFooter">
      <div>
        <div className="container mt-4 border border-5 rounded border-top-0 border-start-0">
          <div
            className="row text-center text-white bg-dark"
            style={{
              fontFamily: "comic sans ms",
              backgroundColor: "#f77604",
            }}
          >
            <h4>Contact us</h4>
          </div>
          <div className="row">
            <div className="col-1"></div>

            <div className="col-10">
              <form onSubmit={handleSubmit}>
                <h6 className="mt-2">
                  Questions?&nbsp;&nbsp;Comments?&nbsp;&nbsp;Ideas?&nbsp;&nbsp;We
                  look forward to hearing from you!
                </h6>
                <div className="row">
                  <input
                    id="firstName"
                    className="form-control"
                    type="text"
                    placeholder="First Name"
                    name="FirstName"
                    value={initialUserData.firstName}
                    onChange={handleUserInput}
                    required
                  ></input>
                </div>
                <div className="row mt-2">
                  <input
                    id="lastName"
                    className="form-control"
                    type="text"
                    placeholder="Last Name"
                    name="LastName"
                    value={initialUserData.lastName}
                    onChange={handleUserInput}
                    required
                  ></input>
                </div>
                <div className="row mt-2">
                  <input
                    id="email"
                    className="form-control"
                    type="email"
                    placeholder="Email"
                    name="Email"
                    value={initialUserData.email}
                    onChange={handleUserInput}
                    required
                  ></input>
                </div>
                <div className="row mt-2">
                  <textarea
                    id="message"
                    className="form-control"
                    type="text"
                    placeholder="Write your message here"
                    rows={5}
                    name="Message"
                    value={initialUserData.message}
                    onChange={handleUserInput}
                    required
                  ></textarea>
                </div>
                <div className="row mt-2 mb-2">
                  <button
                    id="btnSubmit"
                    className="form-control btn btn-primary"
                    type="submit"
                    disabled={loading}
                    style={{
                      fontFamily: "comic sans ms",
                      backgroundColor: "#f48a6c",
                      borderColor: "#ea5a42",
                    }}
                  >
                    {loading ? <MiniLoader /> : "Submit"}
                  </button>
                </div>
              </form>
            </div>

            <div className="col-1"></div>
          </div>
        </div>

        <dialog id="contactUsModal" className="p-0">
          <div className="row bg-dark m-0 p-0" style={{ height: "40px" }}></div>
          <div className="row p-0">
            <div className="col-1 p-0"></div>
            <div className="col-10 p-0">
              <div className="text-center mt-2 mb-2">
                <p>Your message has been sent!</p>
                <button className="btn btn-secondary" id="closeModal">
                  Close
                </button>
              </div>
            </div>
            <div className="col-1 p-0"></div>
          </div>
          <div className="row bg-dark m-0 p-0" style={{ height: "40px" }}></div>
        </dialog>
      </div>
    </div>
  );
}

export default ContactUs;
