import React from "react";
import SkunkCard from "./SkunkCard";
// import mommaSkunkertonPhoto from "../../../images/MommaSkunkerton.jpg";
// import poppaSkunkertonPhoto from "../../../images/PoppaSkunkerton.jpg";
// import sneakyPetePhoto from "../../../images/SneakyPete.jpg";
// import squirrelPupPhoto from "../../../images/SquirrelPup.jpg";
// import sunflowerPhoto from "../../../images/Sunflower.jpg";
// import stinkareenaPhoto from "../../../images/Stinkareena.jpg";
// import smellarellaPhoto from "../../../images/Smellarella.jpg";
// import savyPhoto from "../../../images/Savy.jpg";
import mommaSkunkertonPhoto from "../../../images/meetMomma.png";
import poppaSkunkertonPhoto from "../../../images/meetPoppa.png";
import sneakyPetePhoto from "../../../images/meetPete.png";
import squirrelPupPhoto from "../../../images/meetDave.png";
import sunflowerPhoto from "../../../images/meetMegan.png";
import stinkareenaPhoto from "../../../images/meetMaureen.png";
import smellarellaPhoto from "../../../images/meetSarah.png";
import savyPhoto from "../../../images/meetSheila.png";

import { Skunk } from "./skunk";

function MeetTheSkunkertons() {
  const mommaSkunkerton = new Skunk(
    1,
    "Momma Skunkerton",
    mommaSkunkertonPhoto,
    "A very motherly momma who loves to snuggle her kits.",
    "Baking sweet treats",
    "Troublemakers",
    "#70bf54",
    "#00854a"
  );

  const poppaSkunkerton = new Skunk(
    2,
    "Poppa Skunkerton",
    poppaSkunkertonPhoto,
    "A real pontoon poppa. When he's not fixing things around the house, Poppa Skunkerton is a pontoon boat captain.",
    "Mowing the lawn",
    "Rainy days",
    "#1e92a2",
    "#6ccada"
  );

  const sneakyPete = new Skunk(
    3,
    "Sneaky Pete",
    sneakyPetePhoto,
    "The oldest of the Skunkerton kids. Don't let the name fool you, Sneaky Pete helps look after his younger siblings.",
    "Shooting hoops",
    "A messy room",
    "#e49f15",
    "#feee00"
  );

  const squirrelPup = new Skunk(
    4,
    "Squirrel Pup",
    squirrelPupPhoto,
    "Where there's mischief, there's usually a Squirrel Pup.",
    "Barefoot water skiing",
    "Chores",
    "#f48a6c",
    "#ea5a42"
  );

  const sunflower = new Skunk(
    5,
    "Sunflower",
    sunflowerPhoto,
    "The oldest Skunkerton sister. A skunk with some artsy flair.",
    "Painting",
    "Watching TV",
    "#feee00",
    "#e49f15"
  );

  const stinkareena = new Skunk(
    6,
    "Stinkareena",
    stinkareenaPhoto,
    "Skunk spray isn't just for self-defense, sometimes it can be used for a laugh.",
    "Dogs",
    "Spiders",
    "#00854a",
    "#70bf54"
  );

  const smellarella = new Skunk(
    7,
    "Smellarella",
    smellarellaPhoto,
    "A cuddly and curious kit and Savy's twin sister.",
    "Sprinkled donuts",
    "Doing laundry",
    "#ea5a42",
    "#f48a6c"
  );

  const savy = new Skunk(
    8,
    "Savy",
    savyPhoto,
    "Smellarella's twin sister and the MVP of any sports team.",
    "Softball and Basketball",
    "Most foods... what a picky eater!",
    "#6ccada",
    "#1e92a2"
  );

  const skunks = [
    mommaSkunkerton,
    poppaSkunkerton,
    sneakyPete,
    squirrelPup,
    sunflower,
    stinkareena,
    smellarella,
    savy,
  ];

  const skunkCards = [];
  let i = 1;

  skunks.forEach((skunk) => {
    skunkCards.push(
      <div className="col-lg-3 col-md-6 col-sm-12 meetCard" key={i++}>
        <SkunkCard
          key={skunk.id}
          name={skunk.name}
          photo={skunk.photo}
          bio={skunk.bio}
          likes={skunk.likes}
          dislikes={skunk.dislikes}
          specialColor={skunk.specialColor}
          specialColor2={skunk.specialColor2}
        />
      </div>
    );
  });

  return (
    <div>
      <div className="container padFooter">
        <div className="container mt-4 border border-5 rounded border-top-0 border-start-0">
          <div
            className="row text-center text-white bg-dark"
            style={{
              fontFamily: "comic sans ms",
              backgroundColor: "#02a0b8",
            }}
          >
            <h4>Meet The Skunkertons</h4>
          </div>
          <div className="row">
            <span style={{ fontSize: "14px" }}>
              Click on each skunk to learn more about them
            </span>
          </div>
          <div className="row mt-2">
            <div className="container mt-2 padFooter">
              <div className="row meetCard gutter-2 gx-3 gy-3">
                {skunkCards}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MeetTheSkunkertons;
