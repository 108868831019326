import React from "react";
import { useState } from "react";

function SkunkCard({
  id,
  name,
  photo,
  bio,
  likes,
  dislikes,
  specialColor,
  specialColor2,
}) {
  const [sideOfCard, setSideOfCard] = useState("front");

  if (sideOfCard == "front") {
    return (
      <div
        className="container border border-4 rounded border-top-0 border-start-0 skunkCard animate__animated animate__flipInX"
        //className="container border border-4 rounded border-top-0 border-start-0 skunkCard"
        onClick={() => setSideOfCard("back")}
        style={{ height: "290px", width: "300px" }}
        key={id}
      >
        <div
          className="row d-flex align-items-center"
          style={{ height: "100%" }}
          key={id}
        >
          <div className="col-12 d-flex justify-content-center" key={id}>
            <img src={photo} className="img-fluid" key={id}></img>
          </div>

          <div className="row d-flex align-items-center">
            <div className="row bottomCard text-center">
              <button
                className="btn btn-link"
                type="button"
                onClick={() => setSideOfCard("back")}
                key={id}
                style={{ color: specialColor }}
              >
                <i className="bi bi-arrow-clockwise" key={id}></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="container border border-3 border-dark rounded border-bottom-0 border-end-0 text-white skunkCard animate__animated animate__flipInY"
        // className="container border border-3 border-dark rounded border-bottom-0 border-end-0 text-white skunkCard"
        style={{
          backgroundColor: specialColor,
          height: "290px",
          width: "300px",
        }}
        onClick={() => setSideOfCard("front")}
      >
        <div className="row meetCard" style={{ color: "black" }}>
          {/* <div className="row meetCard" style={{ color: specialColor2 }}> */}
          <h4>{name}</h4>
          <p>{bio}</p>
          <p>
            <strong>Likes: </strong>
            {likes}
          </p>
          <p>
            <strong>Dislikes: </strong>
            {dislikes}
          </p>
        </div>
        <div className="row d-flex align-items-center">
          <div className="row bottomCard text-center">
            <button
              className="btn btn-link"
              type="button"
              onClick={() => setSideOfCard("front")}
              style={{ color: specialColor2 }}
            >
              <i className="bi bi-arrow-clockwise"></i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default SkunkCard;
