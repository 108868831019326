import React from "react";
import { useState } from "react";

function Book({ title, frontCover, backcover, paperbackLink, hardcoverLink }) {
  const [sideOfCard, setSideOfCard] = useState("front");
  return (
    <div className="padFooter">
      <div className="container mt-4 border border-5 rounded border-top-0 border-start-0">
        <div
          className="row text-center text-white bg-dark"
          style={{
            fontFamily: "comic sans ms",
          }}
        >
          <h4>{title}</h4>
        </div>
        <div className="row">
          <h6>
            {/* Coming Soon! */}
            Buy now on{" "}
            <a href={paperbackLink} target="_blank">
              Amazon
            </a>
          </h6>

          {/* <h6>
            Buy now: <a href={paperbackLink}>Paperback</a> |{" "}
            <a href={hardcoverLink}>Hardcover</a>
          </h6> */}
        </div>
        <div className="row pb-2">
          <div className="bookCoverContainer d-flex justify-contents-center">
            {sideOfCard == "front" ? (
              <img
                src={frontCover}
                className="img-fluid animate__animated animate__flipInX"
                onClick={() => setSideOfCard("back")}
              ></img>
            ) : (
              <img
                src={backcover}
                className="img-fluid animate__animated animate__flipInY"
                onClick={() => setSideOfCard("front")}
              ></img>
            )}

            {sideOfCard == "front" ? (
              <i
                className="bi bi-arrow-clockwise text-white"
                onClick={() => setSideOfCard("back")}
              ></i>
            ) : (
              <i
                className="bi bi-arrow-clockwise text-white"
                onClick={() => setSideOfCard("front")}
              ></i>
            )}
          </div>
        </div>
        <span style={{ fontSize: "14px" }}>
          Click to see the {sideOfCard == "front" ? "back" : "front"} cover
        </span>
      </div>
    </div>
  );
}

export default Book;
