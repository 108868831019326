import React from "react";
import MemoryGameBoard from "./MemoryGameBoard";
import { SkunkMemory } from "./skunkMemory";
import mommaPhoto from "../../../../images/Memcard1.png";
import poppaPhoto from "../../../../images/Memcard2.png";
import smellarellaPhoto from "../../../../images/Memcard3.png";
import savyPhoto from "../../../../images/Memcard4.png";
import sneakyPetePhoto from "../../../../images/Memcard5.png";
import squirrelPupPhoto from "../../../../images/Memcard6.png";
import stinkareenaPhoto from "../../../../images/Memcard7.png";
import sunflowerPhoto from "../../../../images/Memcard8.png";

function MeetTheSkunkertonsGame() {
  const mommaSkunkerton = new SkunkMemory(
    1,
    1,
    "Momma Skunkerton",
    mommaPhoto,
    "back",
    "#bb017a",
    false
  );
  const poppaSkunkerton = new SkunkMemory(
    2,
    2,
    "Poppa Skunkerton",
    poppaPhoto,
    "back",
    "#bb017a",
    false
  );
  const smellarella = new SkunkMemory(
    3,
    3,
    "Smellarella",
    smellarellaPhoto,
    "back",
    "#bb017a",
    false
  );
  const savy = new SkunkMemory(
    4,
    4,
    "Savy",
    savyPhoto,
    "back",
    "#bb017a",
    false
  );
  const sneakyPete = new SkunkMemory(
    5,
    5,
    "Sneaky Pete",
    sneakyPetePhoto,
    "back",
    "#bb017a",
    false
  );
  const squirrelPup = new SkunkMemory(
    6,
    6,
    "Squirrel Pup",
    squirrelPupPhoto,
    "back",
    "#bb017a",
    false
  );
  const stinkareena = new SkunkMemory(
    7,
    7,
    "Stinkareena",
    stinkareenaPhoto,
    "back",
    "#bb017a",
    false
  );
  const sunflower = new SkunkMemory(
    8,
    8,
    "Sunflower",
    sunflowerPhoto,
    "back",
    "#bb017a",
    false
  );
  const mommaSkunkerton2 = new SkunkMemory(
    9,
    1,
    "Momma Skunkerton",
    mommaPhoto,
    "back",
    "#bb017a",
    false
  );
  const poppaSkunkerton2 = new SkunkMemory(
    10,
    2,
    "Poppa Skunkerton",
    poppaPhoto,
    "back",
    "#bb017a",
    false
  );
  const smellarella2 = new SkunkMemory(
    11,
    3,
    "Smellarella",
    smellarellaPhoto,
    "back",
    "#bb017a",
    false
  );
  const savy2 = new SkunkMemory(
    12,
    4,
    "Savy",
    savyPhoto,
    "back",
    "#bb017a",
    false
  );
  const sneakyPete2 = new SkunkMemory(
    13,
    5,
    "Sneaky Pete",
    sneakyPetePhoto,
    "back",
    "#bb017a",
    false
  );
  const squirrelPup2 = new SkunkMemory(
    14,
    6,
    "Squirrel Pup",
    squirrelPupPhoto,
    "back",
    "#bb017a",
    false
  );
  const stinkareena2 = new SkunkMemory(
    15,
    7,
    "Stinkareena",
    stinkareenaPhoto,
    "back",
    "#bb017a",
    false
  );
  const sunflower2 = new SkunkMemory(
    16,
    8,
    "Sunflower",
    sunflowerPhoto,
    "back",
    "#bb017a",
    false
  );

  const skunks = [
    mommaSkunkerton,
    poppaSkunkerton,
    sneakyPete,
    squirrelPup,
    sunflower,
    stinkareena,
    smellarella,
    savy,
    mommaSkunkerton2,
    poppaSkunkerton2,
    sneakyPete2,
    squirrelPup2,
    sunflower2,
    stinkareena2,
    smellarella2,
    savy2,
  ];

  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  shuffle(skunks);

  let i = 0;
  skunks.forEach((skunk) => {
    skunk.ind = i;
    i++;
  });

  // console.log(skunks);

  return (
    <div className="container">
      <div className="container mt-4 border border-5 rounded border-top-0 border-start-0">
        <div
          className="row text-center text-white bg-dark"
          style={{
            fontFamily: "comic sans ms",
          }}
        >
          <h4>Memory</h4>
        </div>

        <div className="container pt-2 pb-4">
          <MemoryGameBoard skunks={skunks} />
        </div>
      </div>
    </div>
  );
}

export default MeetTheSkunkertonsGame;
