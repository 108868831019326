export class SkunkMemory {
  constructor(ind, skunkId, name, photo, sideOfCard, specialColor, matched) {
    this.ind = ind;
    this.skunkId = skunkId;
    this.name = name;
    this.photo = photo;
    this.sideOfCard = sideOfCard;
    this.specialColor = specialColor;
    this.matched = matched;
  }
}
