// export class Skunk {
//   constructor(name, photo, funFact1, funFact2, funFact3, specialColor) {
//     this.name = name;
//     this.photo = photo;
//     this.funFact1 = funFact1;
//     this.funFact2 = funFact2;
//     this.funFact3 = funFact3;
//     this.specialColor = specialColor;
//   }
// }

export class Skunk {
  constructor(
    id,
    name,
    photo,
    bio,
    likes,
    dislikes,
    specialColor,
    specialColor2
  ) {
    this.id = id;
    this.name = name;
    this.photo = photo;
    this.bio = bio;
    this.likes = likes;
    this.dislikes = dislikes;
    this.specialColor = specialColor;
    this.specialColor2 = specialColor2;
  }
}
