import React from "react";
import Book from "../Books/Book";
import { NavLink } from "react-router-dom";
import SkunkWhoCouldntSmellFrontCover from "../../../images/SkunkWhoCouldntSmellFrontCover.jpg";
import SkunkWhoCouldntSmellBackCover from "../../../images/SkunkWhoCouldntSmellBackCover.jpg";

function Books() {
  return (
    <div>
      <div>
        <div className="container">
          {/* <div className="row pt-3">
            <div className="sm-col-1 lg-col-4"></div>
            <div className="sm-col-10 lg-col-4 d-flex justify-content-center">
              <NavLink
                className="btn text-white"
                to="/BeTheFirstToKnow"
                style={{
                  fontFamily: "comic sans ms",
                  backgroundColor: "#70bf54",
                  borderColor: "#00854a",
                }}
              >
                Be the first to know!
              </NavLink>
            </div>
            <div className="sm-col-1 lg-col-4"></div>
          </div> */}

          <div className="row">
            <div className="d-flex justify-content-center">
              <Book
                title="The Skunk Who Couldn't Smell"
                frontCover={SkunkWhoCouldntSmellFrontCover}
                backcover={SkunkWhoCouldntSmellBackCover}
                paperbackLink="https://www.amazon.com/Skunk-Who-Couldnt-Smell/dp/B0CJHB9HR7/ref=sr_1_1?crid=YNLGXKMSN8TX&keywords=the+skunk+who+couldn%27t+smell&qid=1695572122&sprefix=the+skunk+who+%2Caps%2C267&sr=8-1"
                hardcoverLink="#"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Books;
