import React, { useState, useEffect } from "react";
import MiniLoader from "../../Reusables/MiniLoader";
import inputHelper from "../../Reusables/inputHelper";
import { NavLink } from "react-router-dom";
import mainUrl from "../../Reusables/url";

function BeTheFirstToKnow() {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const url = `${mainUrl}MailingList`;
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(userInput),
      headers: { "Content-type": "application/json; charset=UTF-8" },
    };

    const response = await fetch(url, requestOptions);
    const responseData = await response.json();

    if (responseData.isSuccess) {
      setLoading(false);
      const firstName = document.getElementById("firstName");
      firstName.value = "";
      const lastName = document.getElementById("lastName");
      lastName.value = "";
      const email = document.getElementById("email");
      email.value = "";
      document.getElementById("addedToMailingListModal").showModal();
      const closeModal = document.getElementById("closeModal");
      closeModal.addEventListener("click", () => {
        document.getElementById("addedToMailingListModal").close();
      });
    }
  };

  const initialUserData = {
    FirstName: "",
    LastName: "",
    Email: "",
  };

  const [userInput, setUserInput] = useState(initialUserData);
  const handleUserInput = (e) => {
    const tempData = inputHelper(e, userInput);
    setUserInput(tempData);
  };

  return (
    <div className="container padFooter">
      <div>
        <div className="container mt-4 border border-5 rounded border-top-0 border-start-0">
          <div
            className="row text-center text-white bg-dark"
            style={{
              fontFamily: "comic sans ms",
              backgroundColor: "#57c9da",
            }}
          >
            <h4>Be the first to know!</h4>
          </div>
          <div className="row">
            <div className="col-1"></div>

            <div className="col-10">
              <form onSubmit={handleSubmit}>
                <h6 className="mt-2">
                  Subscribe to our mailing list!&nbsp;&nbsp;We promise not to
                  spam you.&nbsp;&nbsp;We'll just let you know about new books
                  and upcoming events.
                </h6>
                <div className="row">
                  <input
                    id="firstName"
                    className="form-control"
                    type="text"
                    placeholder="First Name"
                    name="FirstName"
                    value={initialUserData.firstName}
                    onChange={handleUserInput}
                    required
                  ></input>
                </div>
                <div className="row mt-2">
                  <input
                    id="lastName"
                    className="form-control"
                    type="text"
                    placeholder="Last Name"
                    name="LastName"
                    value={initialUserData.lastName}
                    onChange={handleUserInput}
                    required
                  ></input>
                </div>
                <div className="row mt-2">
                  <input
                    id="email"
                    className="form-control"
                    type="email"
                    placeholder="Email"
                    name="Email"
                    value={initialUserData.email}
                    onChange={handleUserInput}
                    required
                  ></input>
                </div>

                <div className="row mt-2 mb-2">
                  <button
                    id="btnSubmit"
                    className="form-control btn btn-primary"
                    type="submit"
                    disabled={loading}
                    style={{
                      fontFamily: "comic sans ms",
                      backgroundColor: "#6ccada",
                      borderColor: "#1e92a2",
                    }}
                  >
                    {loading ? <MiniLoader /> : "Submit"}
                  </button>
                </div>
              </form>
            </div>

            <div className="col-1"></div>
          </div>
        </div>

        <div className="container">
          <div className="row text-center">
            <NavLink to="/Unsubscribe">Unsubscribe</NavLink>
          </div>
        </div>

        <dialog id="addedToMailingListModal" className="p-0">
          <div className="row bg-dark m-0 p-0" style={{ height: "40px" }}></div>
          <div className="row p-0">
            <div className="col-1 p-0"></div>
            <div className="col-10 p-0">
              <div className="text-center mt-2 mb-2">
                <p>Thank you for joining the mailing list!</p>
                <button className="btn btn-secondary" id="closeModal">
                  Close
                </button>
              </div>
            </div>
            <div className="col-1 p-0"></div>
          </div>
          <div className="row bg-dark m-0 p-0" style={{ height: "40px" }}></div>
        </dialog>
      </div>
    </div>
  );
}

export default BeTheFirstToKnow;
