import React from "react";
import backPhoto from "../../../../images/Question mark.png";
import { useState } from "react";

function MemoryGameCard({
  ind,
  skunkId,
  name,
  photo,
  sideOfCard,
  specialColor,
  flip,
}) {
  return (
    <div id={`card${ind}`} className="container memoryCard px-0" onClick={flip}>
      <div>
        {sideOfCard == "back" ? (
          <img
            src={backPhoto}
            className="img-fluid rounded animate__animated animate__flipInX"
          ></img>
        ) : (
          <img
            src={photo}
            className="img-fluid rounded animate__animated animate__flipInY"
          ></img>
        )}
      </div>
    </div>
  );
}

export default MemoryGameCard;
