import React, { useState } from "react";
import MiniLoader from "../../Reusables/MiniLoader";
import inputHelper from "../../Reusables/inputHelper";
import { NavLink } from "react-router-dom";
import mainUrl from "../../Reusables/url";

function Unsubscribe() {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const url = `${mainUrl}MailingList`;
    const requestOptions = {
      method: "DELETE",
      body: JSON.stringify(userInput),
      headers: { "Content-type": "application/json; charset=UTF-8" },
    };

    const response = await fetch(url, requestOptions);
    const responseData = await response.json();

    if (responseData.isSuccess) {
      setLoading(false);
      const message = document.getElementById("deleteMessage");
      message.innerText =
        "The email address you provided has been removed from our mailing list.";
      const email = document.getElementById("email");
      email.value = "";
      document.getElementById("removeFromMailingListModal").showModal();
      const closeModal = document.getElementById("closeModal");
      closeModal.addEventListener("click", () => {
        document.getElementById("removeFromMailingListModal").close();
      });
    } else if (responseData.statusCode == 404) {
      setLoading(false);
      const message = document.getElementById("deleteMessage");
      message.innerText = `The email you provided - ${responseData.result.email} - is not on our mailing list`;
      const email = document.getElementById("email");
      email.value = "";
      document.getElementById("removeFromMailingListModal").showModal();
      const closeModal = document.getElementById("closeModal");
      closeModal.addEventListener("click", () => {
        document.getElementById("removeFromMailingListModal").close();
      });
    }
  };

  const initialUserData = {
    Email: "",
  };

  const [userInput, setUserInput] = useState(initialUserData);
  const handleUserInput = (e) => {
    const tempData = inputHelper(e, userInput);
    setUserInput(tempData);
  };

  return (
    <div className="container padFooter">
      <div>
        <div className="container mt-4 border border-5 rounded border-top-0 border-start-0">
          <div
            className="row text-center text-white bg-dark"
            style={{
              fontFamily: "comic sans ms",
            }}
          >
            <h4>Sorry to see you go!</h4>
          </div>

          <div className="row">
            <div className="col-1"></div>

            <div className="col-10">
              <form onSubmit={handleSubmit}>
                <h6 className="mt-2">
                  Please enter the email address you would like us to remove
                  from our mailing list and click Submit.
                </h6>
                <div className="row mt-2">
                  <input
                    id="email"
                    className="form-control"
                    type="email"
                    placeholder="Email"
                    name="Email"
                    value={initialUserData.email}
                    onChange={handleUserInput}
                    required
                  ></input>
                </div>

                <div className="row mt-2 mb-2">
                  <button
                    id="btnSubmit"
                    className="form-control btn btn-primary"
                    type="submit"
                    disabled={loading}
                    style={{
                      fontFamily: "comic sans ms",
                      backgroundColor: "#ea5a42",
                      borderColor: "#f48a6c",
                    }}
                  >
                    {loading ? <MiniLoader /> : "Submit"}
                  </button>
                </div>
              </form>
            </div>

            <div className="col-1"></div>
          </div>
        </div>

        <div className="container">
          <div className="row text-center">
            <NavLink to="/BeTheFirstToKnow">Close</NavLink>
          </div>
        </div>

        <dialog id="removeFromMailingListModal" className="p-0">
          <div className="row bg-dark m-0 p-0" style={{ height: "40px" }}></div>
          <div className="row p-0">
            <div className="col-1 p-0"></div>
            <div className="col-10 p-0">
              <div className="text-center mt-2 mb-2">
                <p id="deleteMessage"></p>
                <button className="btn btn-secondary" id="closeModal">
                  Close
                </button>
              </div>
            </div>
            <div className="col-1 p-0"></div>
          </div>
          <div className="row bg-dark m-0 p-0" style={{ height: "40px" }}></div>
        </dialog>
      </div>
    </div>
  );
}

export default Unsubscribe;
