import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap-icons/font/bootstrap-icons.css";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HashRouter, Routes, Route } from "react-router-dom";
import MeetTheSkunkertons from "./Components/Pages/MeetTheSkunkertons/MeetTheSkunkertons";
import Books from "./Components/Pages/Books/Books";
import Games from "./Components/Pages/Games/Games";
import About from "./Components/Pages/About/About";
import ContactUs from "./Components/Pages/ContactUs/ContactUs";
import BeTheFirstToKnow from "./Components/Pages/BeTheFirstToKnow/BeTheFirstToKnow";
import Header from "./Components/Layout/Header";
// import Footer from "./Components/Layout/Footer";
import Unsubscribe from "./Components/Pages/BeTheFirstToKnow/Unsubscribe";
// import MeetTheSkunkertonsGame from "./Components/Pages/Games/MeetTheSkunkertonsGame/MeetTheSkunkertonsGame";
import Home from "./Components/Pages/Home/Home";
import Activities from "./Components/Pages/Activities/Activities";
import Memory from "./Components/Pages/Activities/Memory/Memory";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div>
    <HashRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/Meet" element={<MeetTheSkunkertons />}></Route>
        <Route path="/Books" element={<Books />}></Route>
        {/* <Route path="/Games" element={<Games />}></Route> */}
        <Route path="/Activities" element={<Activities />}></Route>
        <Route path="/About" element={<About />}></Route>
        <Route path="/ContactUs" element={<ContactUs />}></Route>
        <Route path="/BeTheFirstToKnow" element={<BeTheFirstToKnow />}></Route>
        <Route path="/Unsubscribe" element={<Unsubscribe />}></Route>
        <Route path="/Memory" element={<Memory />}></Route>
      </Routes>
      {/* <Footer /> */}
    </HashRouter>
  </div>
);
