import React from "react";
import { Link, NavLink } from "react-router-dom";
// import HeaderLogo from "../../images/HeaderLogo.png";
import HeaderLogo from "../../images/ColorHeaderLogo.png";

function Header() {
  function closeNavMenu() {
    const menuBtn = document.getElementById("menuBtn");
    const isExpanded = menuBtn.ariaExpanded;
    if (isExpanded == "true") {
      menuBtn.click();
    }
  }

  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-dark navbar-dark p-0">
        <div className="container-fluid">
          <NavLink className="navbar-brand" to="/" onClick={closeNavMenu}>
            <img
              src={HeaderLogo}
              style={{ maxHeight: "65px", width: "150px" }}
            ></img>
          </NavLink>
          <button
            id="menuBtn"
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item active">
                <NavLink className="nav-link" to="/" onClick={closeNavMenu}>
                  Home
                </NavLink>
              </li>
              <li className="nav-item active">
                <NavLink className="nav-link" to="/Meet" onClick={closeNavMenu}>
                  Meet The Skunkertons
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/Books"
                  onClick={closeNavMenu}
                >
                  Books
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/Activities"
                  onClick={closeNavMenu}
                >
                  Activities
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink className="nav-link" to="/Games" onClick={closeNavMenu}>
                  Games
                </NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/BeTheFirstToKnow"
                  onClick={closeNavMenu}
                >
                  Be the first to know
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/About"
                  onClick={closeNavMenu}
                >
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/ContactUs"
                  onClick={closeNavMenu}
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
