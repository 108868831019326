import React from "react";
import Activity from "../Activities/Activity";
// import SkunkSilhouetteBlack from "../../../images/SkunkSilhouetteBlack.jpg";
import BackOfMemoryCard from "../../../images/Question mark.png";
import ColoringPagePdf from "../../../images/TSWCS_ColoringPage.jpg";
import ColoringPage from "../../../images/ColorPageTSWCS.jpg";

function Activities() {
  return (
    <div>
      <div className="container padFooter">
        <Activity
          name="Memory"
          description="Flip cards.&nbsp;&nbsp;Match cards.&nbsp;&nbsp;Win game!"
          link="/Memory"
          // photo={SkunkSilhouetteBlack}
          photo={BackOfMemoryCard}
          maxSize="150px"
          primaryColor="#70bf54"
          secondaryColor="#00854a"
          action="Play"
        />
        <Activity
          name="Coloring Page"
          description="Oops... we forgot to color this page!&nbsp;&nbsp;Will you please help us?"
          link={ColoringPagePdf}
          photo={ColoringPage}
          maxSize="400px"
          primaryColor="#6ccada"
          secondaryColor="#1e92a2"
          action="Download"
        />
      </div>
    </div>

    // <div className="padFooter">
    //   <div className="container">
    //     {/* <div className="row">
    //       <div className="sm-col-1 md-col-2 lg-col-3"></div>
    //       <div className="sm-col-10 md-col-8 lg-col-6">
    //         <div className="row">
    //           <div className="d-flex justify-content-center">
    //             <Activity
    //               name="Memory"
    //               description="Flip cards.&nbsp;&nbsp;Match cards.&nbsp;&nbsp;Win game!"
    //               link="/Memory"
    //               photo={SkunkSilhouetteBlack}
    //             />
    //           </div>
    //         </div>
    //       </div>
    //       <div className="sm-col-1 md-col-2 lg-col-3"></div>
    //     </div>
    //     <div className="row">
    //       <div className="sm-col-1 md-col-2 lg-col-3"></div>
    //       <div className="sm-col-10 md-col-8 lg-col-6">
    //         <div className="row">
    //           <div className="d-flex justify-content-center">
    //             <Activity
    //               name="Coloring Page"
    //               description="Oops... we forgot to color this page!&nbsp;&nbsp;Will you please help us?"
    //               link="../../../images/TSWCS_ColoringPage.pdf"
    //               photo={ColoringPage}
    //             />
    //           </div>
    //         </div>
    //       </div>
    //       <div className="sm-col-1 md-col-2 lg-col-3"></div>
    //     </div> */}
    //     {/* <div className="row">
    //       <div className="sm-col-1 lg-col-4"></div>
    //       <div className="sm-col-12 lg-col-4 d-flex justify-content-center">
    //         <div className="row">
    //           <div className="d-flex justify-content-center">
    //             <Activity
    //               name="Memory"
    //               description="Flip cards.&nbsp;&nbsp;Match cards.&nbsp;&nbsp;Win game!"
    //               link="/Memory"
    //               photo={SkunkSilhouetteBlack}
    //             />
    //           </div>
    //         </div>
    //         <div className="row">
    //           <div className="d-flex justify-content-center">
    //             <Activity
    //               name="Coloring Page"
    //               description="Oops... we forgot to color this page!&nbsp;&nbsp;Will you please help us?"
    //               link="../../../images/TSWCS_ColoringPage.pdf"
    //               photo={ColoringPage}
    //             />
    //           </div>
    //         </div>
    //       </div>
    //       <div className="sm-col-1 lg-col-4"></div>
    //     </div> */}

    //     {/* <div className="row">
    //       <div className="d-flex justify-content-center">
    //         <Activity
    //           name="Memory"
    //           description="Flip cards.&nbsp;&nbsp;Match cards.&nbsp;&nbsp;Win game!"
    //           link="/Memory"
    //           photo={SkunkSilhouetteBlack}
    //         />
    //       </div>
    //     </div>
    //     <div className="row">
    //       <div className="d-flex justify-content-center">
    //         <Activity
    //           name="Coloring Page"
    //           description="Oops... we forgot to color this page!&nbsp;&nbsp;Will you please help us?"
    //           link="../../../images/TSWCS_ColoringPage.pdf"
    //           photo={ColoringPage}
    //         />
    //       </div>
    //     </div> */}
    //   </div>
    // </div>
  );
}

export default Activities;
