import React from "react";
import skunkertonFamilyPhoto from "../../../images/SkunkertonFamily.jpg";
import MainLogo from "../../../images/MainLogo2.jpg";
import { NavLink } from "react-router-dom";
import whitespace from "../../../images/whitespace.jpg";
import SkunkSilhouetteWhite from "../../../images/SkunkSilhouetteWhite.png";

function Home() {
  return (
    <div>
      <div className="container mt-4 padFooter">
        <div className="row">
          <div id="cf" className="d-flex justify-content-center col-12">
            <img
              className="bottom img-fluid"
              style={{ maxHeight: "400px" }}
              src={MainLogo}
            ></img>
            <img
              className="top img-fluid"
              style={{ maxHeight: "400px" }}
              src={skunkertonFamilyPhoto}
            ></img>
          </div>
          <img
            className="top img-fluid"
            style={{ maxHeight: "400px" }}
            src={whitespace}
          ></img>
        </div>

        <div className="container">
          <div className="row mt-4">
            <div className="container mt-4 border border-5 rounded border-top-0 border-start-0">
              <div
                className="row text-center text-white bg-dark"
                style={{
                  fontFamily: "comic sans ms",
                  backgroundColor: "#02a0b8",
                }}
              >
                <h4>Welcome!</h4>
              </div>
              <div className="row mt-2">
                <p>
                  <span
                    style={{
                      color: "#6ccada",
                      fontWeight: "bold",
                      fontFamily: "comic sans ms",
                    }}
                  >
                    The Skunkertons are an adorable, fun loving, family of
                    skunks!
                  </span>{" "}
                  &nbsp;&nbsp;They are introducing themselves to the world in
                  their first book,{" "}
                  <span
                    style={{
                      color: "#ea5a42",
                      fontWeight: "bold",
                      fontFamily: "comic sans ms",
                    }}
                  >
                    <em>The Skunk Who Couldn't Smell</em>
                  </span>
                  {" - in our opinion, a "}
                  potential{" "}
                  <span
                    style={{
                      color: "#70bf54",
                      fontWeight: "bold",
                      fontFamily: "comic sans ms",
                    }}
                  >
                    best smeller!
                  </span>
                  &nbsp;&nbsp;We hope you{" "}
                  <span
                    style={{
                      color: "#e49f15",
                      fontWeight: "bold",
                      fontFamily: "comic sans ms",
                    }}
                  >
                    have a blast
                  </span>{" "}
                  joining{" "}
                  <span
                    style={{
                      color: "#f48a6c",
                      fontWeight: "bold",
                      fontFamily: "comic sans ms",
                    }}
                  >
                    The Skunkertons
                  </span>{" "}
                  on this first adventure!&nbsp;&nbsp;Click{" "}
                  <NavLink to="/Meet" style={{ textDecoration: "none" }}>
                    <span
                      style={{
                        color: "#1e92a2",
                        fontWeight: "bold",
                        fontFamily: "comic sans ms",
                      }}
                    >
                      here
                    </span>
                  </NavLink>{" "}
                  to get to know{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                      fontFamily: "comic sans ms",
                    }}
                  >
                    The Skunkertons
                  </span>{" "}
                  a little better!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
