import React from "react";
import MemoryGameCard from "./MemoryGameCard";
import { useState } from "react";
import { GameStatus } from "./gameStatus";
import confetti from "canvas-confetti";
import { NavLink } from "react-router-dom";

// found a match confetti
var colors = [
  "#70bf54",
  "#00854a",
  "#e49f15",
  "#feee00",
  "#f48a6c",
  "#ea5a42",
  "#6ccada",
  "#1e92a2",
];

function matchConfetti() {
  confetti({
    particleCount: 100,
    angle: 60,
    spread: 55,
    origin: { x: 0 },
    colors: colors,
  });
  confetti({
    particleCount: 100,
    angle: 120,
    spread: 55,
    origin: { x: 1 },
    colors: colors,
  });
}

// game winning confetti
var duration;
var animationEnd;
var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

function gameConfetti() {
  var interval = setInterval(function () {
    var timeLeft = animationEnd - Date.now();

    if (timeLeft <= 0) {
      return clearInterval(interval);
    }

    var particleCount = 175 * (timeLeft / duration);
    // since particles fall down, start a bit higher than random
    confetti(
      Object.assign({}, defaults, {
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
      })
    );
    confetti(
      Object.assign({}, defaults, {
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
      })
    );
  }, 250);
}

function MemoryGameBoard({ skunks }) {
  const [skunkState, setSkunkState] = useState(skunks);
  const [gameState, setGameState] = useState([]);
  const [matches, setMatches] = useState(0);
  const [cardsFlipped, setCardsFlipped] = useState(0);
  const [moves, setMoves] = useState(0);

  const playAgain = () => window.location.reload(true);

  // updating skunkState to determine if the front or back of each card is currently displaying
  async function flipCard(currentSkunk) {
    const disableFlip = document.getElementById(
      `card${skunkState[currentSkunk].ind}`
    );
    disableFlip.classList.add("tempDisableClick");
    const nextSkunkState = skunkState.slice();
    const side =
      nextSkunkState[currentSkunk].sideOfCard === "back" ? "front" : "back";
    nextSkunkState[currentSkunk].sideOfCard = side;
    setSkunkState(nextSkunkState);
  }

  // update game status
  async function updateGameStatus(currentSkunk) {
    gameState.push(
      new GameStatus(
        skunkState[currentSkunk].ind,
        skunkState[currentSkunk].skunkId,
        skunkState[currentSkunk].name
      )
    );
    setGameState(gameState);
  }

  async function validateGameStatus() {
    // player matched
    if (
      gameState.length === 2 &&
      gameState[0].skunkId === gameState[1].skunkId
    ) {
      // console.log("you found a match");
      const matchCard1 = document.getElementById(`card${gameState[0].ind}`);
      const matchCard2 = document.getElementById(`card${gameState[1].ind}`);
      matchCard1.classList.add("permDisableClick");
      matchCard2.classList.add("permDisableClick");

      const nextMatches = matches + 1;
      setMatches(nextMatches);

      matchConfetti();

      if (nextMatches > 7) {
        //if (nextMatches === 1) {
        duration = 15 * 600;
        animationEnd = Date.now() + duration;
        gameConfetti();
        await delay(3000);
        document.getElementById("winnerModal").showModal();
        //console.log("winner!");
      }

      setGameState([]);
      skunkState.forEach((skunk) => {
        if (skunk.skunkId === gameState[0].skunkId) {
          skunk.matched = true;
        }
      });
      setSkunkState(skunkState);

      // player mis-matched
    } else if (
      gameState.length === 2 &&
      gameState[0].skunkId !== gameState[1].skunkId
    ) {
      //console.log("not a match");
      skunkState.forEach((skunk) => {
        if (!skunk.matched) {
          skunk.sideOfCard = "back";
        }
      });
      const disableCardClick = document.getElementsByClassName("memoryCard");
      for (var i = 0; i < disableCardClick.length; i++) {
        disableCardClick.item(i).classList.add("tempDisableClick");
      }
      // const allCards = document.getElementById("allCards");
      // allCards.classList.add("gameBoard");
      const noMatchIndicator = document.getElementById("gameWindow");
      await delay(275);
      noMatchIndicator.classList.add("noMatch");
      await delay(900);
      setSkunkState(skunkState);
      setGameState([]);
      const enableCardClick = document.getElementsByClassName("memoryCard");
      for (var i = 0; i < enableCardClick.length; i++) {
        enableCardClick.item(i).classList.remove("tempDisableClick");
      }
      // allCards.classList.remove("gameBoard");
      noMatchIndicator.classList.remove("noMatch");
      // only one card flipped
    } else {
      //console.log("flip another card");
    }
  }

  function delay(millisec) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve("");
      }, millisec);
    });
  }

  async function cardsNeedReset(currentSkunk) {
    if (gameState.length === 2) {
      setGameState([]);
      setSkunkState(skunkState);
    }
  }

  const [movesColor, setMovesColor] = useState("#000000");
  function handleMoves() {
    const nextCardsFlipped = cardsFlipped + 1;
    if (nextCardsFlipped % 2 === 0) {
      setMoves(nextCardsFlipped / 2);

      if (nextCardsFlipped / 2 > 13) {
        setMovesColor("#ea5a42");
      } else if (nextCardsFlipped / 2 > 10) {
        setMovesColor("#6ccada");
      } else if (nextCardsFlipped / 2 > 7) {
        setMovesColor("#00854a");
      }
    }
    setCardsFlipped(nextCardsFlipped);
  }

  async function handleClick(p) {
    handleMoves();
    await cardsNeedReset(p);
    await flipCard(p);
    await updateGameStatus(p);
    await validateGameStatus();
  }

  const skunkCards = [];
  let i = 0;

  // skunkState.forEach((skunk) => {
  //   skunkCards.push(
  //     <div className="col-lg-3 col-md-6 col-sm-12 meetCard" key={i}>
  //       <MemoryGameCard
  //         ind={skunk.ind}
  //         skunkId={skunk.skunkId}
  //         name={skunk.name}
  //         photo={skunk.photo}
  //         sideOfCard={skunk.sideOfCard}
  //         specialColor={skunk.specialColor}
  //         flip={() => handleClick(skunk.ind)}
  //       />
  //     </div>
  //   );
  //   i++;
  // });

  skunkState.forEach((skunk) => {
    skunkCards.push(
      <div className="meetCard" key={i}>
        <MemoryGameCard
          ind={skunk.ind}
          skunkId={skunk.skunkId}
          name={skunk.name}
          photo={skunk.photo}
          sideOfCard={skunk.sideOfCard}
          specialColor={skunk.specialColor}
          flip={() => handleClick(skunk.ind)}
        />
      </div>
    );
    i++;
  });

  // console.log(skunkState);

  return (
    <div>
      <div className="row text-center">
        <div className="col">
          <h4>
            <span
              style={{
                color: movesColor,
                fontWeight: "bold",
                fontFamily: "comic sans ms",
              }}
            >
              Moves {moves}
            </span>
          </h4>
        </div>
        <div className="col">
          <button
            // className="btn btn-dark text-white"
            className="btn btn-link text-dark p-0"
            type="button"
            onClick={playAgain}
          >
            <h4>Restart</h4>
          </button>
        </div>
      </div>

      {/* <div id="gameWindow">
        <div className="container pt-3">
          <div className="row meetCard gutter-2 gx-3 gy-3">{skunkCards}</div>
        </div> */}

      <div id="gameWindow">
        <div className="container pt-3 pb-3">
          <div className="row mt-2">
            <div className="col">{skunkCards[0]}</div>
            <div className="col">{skunkCards[1]}</div>
            <div className="col">{skunkCards[2]}</div>
            <div className="col">{skunkCards[3]}</div>
          </div>
          <div className="row mt-2">
            <div className="col">{skunkCards[4]}</div>
            <div className="col">{skunkCards[5]}</div>
            <div className="col">{skunkCards[6]}</div>
            <div className="col">{skunkCards[7]}</div>
          </div>
          <div className="row mt-2">
            <div className="col">{skunkCards[8]}</div>
            <div className="col">{skunkCards[9]}</div>
            <div className="col">{skunkCards[10]}</div>
            <div className="col">{skunkCards[11]}</div>
          </div>
          <div className="row mt-2">
            <div className="col">{skunkCards[12]}</div>
            <div className="col">{skunkCards[13]}</div>
            <div className="col">{skunkCards[14]}</div>
            <div className="col">{skunkCards[15]}</div>
          </div>
        </div>

        <dialog id="winnerModal" className="p-0">
          <div className="row bg-dark m-0 p-0" style={{ height: "40px" }}></div>
          <div className="row p-0">
            <div className="col-1 p-0"></div>
            <div className="col-10 p-0">
              <div className="text-center mt-2 mb-2">
                <h2>
                  <span
                    style={{
                      color: "#70bf54",
                      fontWeight: "bold",
                      fontFamily: "comic sans ms",
                    }}
                  >
                    Winner!
                  </span>
                </h2>
                <p>
                  You got them all in{" "}
                  <span
                    style={{
                      // color: "#6ccada",
                      color: movesColor,
                      fontWeight: "bold",
                      fontFamily: "comic sans ms",
                    }}
                  >
                    {moves} moves
                  </span>
                  .&nbsp;&nbsp;Think you can win in fewer moves?&nbsp;&nbsp;
                  <span
                    style={{
                      color: "#1e92a2",
                      fontWeight: "bold",
                      fontFamily: "comic sans ms",
                    }}
                  >
                    Give it a shot!
                  </span>
                </p>
              </div>
              <div className="row text-center">
                <div className="col-2"></div>
                <div className="col-8">
                  <button
                    className="btn btn-dark text-white"
                    type="button"
                    onClick={playAgain}
                  >
                    Play again!
                  </button>
                </div>
                <div className="col-2"></div>
              </div>
              <div className="row text-center">
                <NavLink
                  to="/Activities"
                  className="text-dark"
                  style={{ textDecoration: "none" }}
                >
                  Quit
                </NavLink>
              </div>
            </div>
            <div className="col-1 p-0"></div>
          </div>
          <div className="row bg-dark m-0 p-0" style={{ height: "40px" }}></div>
        </dialog>
      </div>
    </div>
  );
}

export default MemoryGameBoard;

// import React from "react";
// import MemoryGameCard from "./MemoryGameCard";
// import { useState, useEffect } from "react";
// import { GameStatus } from "./gameStatus";
// import confetti from "canvas-confetti";
// import { NavLink } from "react-router-dom";

// // found a match confetti
// var colors = [
//   "#70bf54",
//   "#00854a",
//   "#e49f15",
//   "#feee00",
//   "#f48a6c",
//   "#ea5a42",
//   "#6ccada",
//   "#1e92a2",
// ];

// function matchConfetti() {
//   confetti({
//     particleCount: 100,
//     angle: 60,
//     spread: 55,
//     origin: { x: 0 },
//     colors: colors,
//   });
//   confetti({
//     particleCount: 100,
//     angle: 120,
//     spread: 55,
//     origin: { x: 1 },
//     colors: colors,
//   });
// }

// // game winning confetti
// var duration;
// var animationEnd;
// var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

// function randomInRange(min, max) {
//   return Math.random() * (max - min) + min;
// }

// function gameConfetti() {
//   var interval = setInterval(function () {
//     var timeLeft = animationEnd - Date.now();

//     if (timeLeft <= 0) {
//       return clearInterval(interval);
//     }

//     var particleCount = 175 * (timeLeft / duration);
//     // since particles fall down, start a bit higher than random
//     confetti(
//       Object.assign({}, defaults, {
//         particleCount,
//         origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
//       })
//     );
//     confetti(
//       Object.assign({}, defaults, {
//         particleCount,
//         origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
//       })
//     );
//   }, 250);
// }

// function MemoryGameBoard({ skunks }) {
//   const [skunkState, setSkunkState] = useState(skunks);
//   const [gameState, setGameState] = useState([]);
//   const [matches, setMatches] = useState(0);
//   const [time, setTime] = useState(0);
//   const [isRunning, setIsRunning] = useState(false);

//   useEffect(() => {
//     let intervalId;
//     if (isRunning) {
//       // setting time from 0 to 1 every 10 milisecond using javascript setInterval method
//       intervalId = setInterval(() => setTime(time + 1), 10);
//     }
//     return () => clearInterval(intervalId);
//   }, [isRunning, time]);

//   const minutes = Math.floor((time % 360000) / 6000);
//   const seconds = Math.floor((time % 6000) / 100);
//   const milliseconds = time % 100;

//   const playAgain = () => window.location.reload(true);

//   // updating skunkState to determine if the front or back of each card is currently displaying
//   async function flipCard(currentSkunk) {
//     const disableFlip = document.getElementById(
//       `card${skunkState[currentSkunk].ind}`
//     );
//     disableFlip.classList.add("tempDisableClick");
//     const nextSkunkState = skunkState.slice();
//     const side =
//       nextSkunkState[currentSkunk].sideOfCard === "back" ? "front" : "back";
//     nextSkunkState[currentSkunk].sideOfCard = side;
//     setSkunkState(nextSkunkState);
//   }

//   // update game status
//   async function updateGameStatus(currentSkunk) {
//     gameState.push(
//       new GameStatus(
//         skunkState[currentSkunk].ind,
//         skunkState[currentSkunk].skunkId,
//         skunkState[currentSkunk].name
//       )
//     );
//     setGameState(gameState);
//   }

//   async function validateGameStatus() {
//     // player matched
//     if (
//       gameState.length === 2 &&
//       gameState[0].skunkId === gameState[1].skunkId
//     ) {
//       // console.log("you found a match");
//       const matchCard1 = document.getElementById(`card${gameState[0].ind}`);
//       const matchCard2 = document.getElementById(`card${gameState[1].ind}`);
//       matchCard1.classList.add("permDisableClick");
//       matchCard2.classList.add("permDisableClick");

//       const nextMatches = matches + 1;
//       setMatches(nextMatches);

//       matchConfetti();

//       // const matchIndicator = document.getElementById("gameWindow");
//       // await delay(400);
//       // matchIndicator.classList.add("yesMatch");
//       // await delay(700);
//       // matchIndicator.classList.remove("yesMatch");

//       if (nextMatches > 7) {
//         // if (nextMatches === 1) {
//         duration = 15 * 1000;
//         animationEnd = Date.now() + duration;
//         gameConfetti();
//         await delay(3000);
//         document.getElementById("winnerModal").showModal();
//         console.log("winner!");
//       }

//       setGameState([]);
//       skunkState.forEach((skunk) => {
//         if (skunk.skunkId === gameState[0].skunkId) {
//           skunk.matched = true;
//         }
//       });
//       setSkunkState(skunkState);

//       // player mis-matched
//     } else if (
//       gameState.length === 2 &&
//       gameState[0].skunkId !== gameState[1].skunkId
//     ) {
//       //console.log("not a match");
//       skunkState.forEach((skunk) => {
//         if (!skunk.matched) {
//           skunk.sideOfCard = "back";
//         }
//       });
//       const disableCardClick = document.getElementsByClassName("memoryCard");
//       for (var i = 0; i < disableCardClick.length; i++) {
//         disableCardClick.item(i).classList.add("tempDisableClick");
//       }
//       // const allCards = document.getElementById("allCards");
//       // allCards.classList.add("gameBoard");
//       const noMatchIndicator = document.getElementById("gameWindow");
//       await delay(275);
//       noMatchIndicator.classList.add("noMatch");
//       await delay(900);
//       setSkunkState(skunkState);
//       setGameState([]);
//       const enableCardClick = document.getElementsByClassName("memoryCard");
//       for (var i = 0; i < enableCardClick.length; i++) {
//         enableCardClick.item(i).classList.remove("tempDisableClick");
//       }
//       // allCards.classList.remove("gameBoard");
//       noMatchIndicator.classList.remove("noMatch");
//       // only one card flipped
//     } else {
//       //console.log("flip another card");
//     }
//     // console.log("validateGameStatus");
//     // console.log(skunkState);
//   }

//   function delay(millisec) {
//     return new Promise((resolve) => {
//       setTimeout(() => {
//         resolve("");
//       }, millisec);
//     });
//   }

//   async function cardsNeedReset(currentSkunk) {
//     if (gameState.length === 2) {
//       setGameState([]);
//       setSkunkState(skunkState);
//       // console.log("cards reset");
//     }
//     // console.log("cardsNeedReset");
//     // console.log(skunkState);
//   }

//   async function startTimer() {
//     if (!isRunning) {
//       setIsRunning(true);
//     }
//     // console.log("startTimer");
//     // console.log(skunkState);
//   }

//   async function handleClick(p) {
//     await startTimer();
//     await cardsNeedReset(p);
//     await flipCard(p);
//     await updateGameStatus(p);
//     //console.log(gameState);
//     await validateGameStatus();
//     // console.log(skunkState);
//   }

//   const skunkCards = [];
//   let i = 0;

//   skunkState.forEach((skunk) => {
//     skunkCards.push(
//       <div className="col-lg-3 col-md-6 col-sm-12 meetCard" key={i}>
//         <MemoryGameCard
//           ind={skunk.ind}
//           skunkId={skunk.skunkId}
//           name={skunk.name}
//           photo={skunk.photo}
//           sideOfCard={skunk.sideOfCard}
//           specialColor={skunk.specialColor}
//           flip={() => handleClick(skunk.ind)}
//         />
//       </div>
//     );
//     i++;
//   });

//   // console.log(skunkState);

//   return (
//     <div>
//       <div className="stopwatch-container">
//         <p className="stopwatch-time">
//           {minutes.toString().padStart(2, "0")}:
//           {seconds.toString().padStart(2, "0")}:
//           {milliseconds.toString().padStart(2, "0")}
//         </p>
//         {/* <div className="stopwatch-buttons">
//           <button className="stopwatch-button" onClick={startAndStop}>
//             {isRunning ? "Stop" : "Start"}
//           </button>
//           <button className="stopwatch-button" onClick={reset}>
//             Reset
//           </button>
//         </div> */}
//       </div>
//       <div id="gameWindow">
//         <div className="container pt-3">
//           <div className="row meetCard gutter-2 gx-3 gy-3">{skunkCards}</div>
//         </div>

//         <dialog id="winnerModal" className="p-0">
//           <div className="row bg-dark m-0 p-0" style={{ height: "40px" }}></div>
//           <div className="row p-0">
//             <div className="col-1 p-0"></div>
//             <div className="col-10 p-0">
//               <div className="text-center mt-2 mb-2">
//                 <p>Awesome job, you got them all!!</p>
//               </div>
//               <div className="row text-center">
//                 <div className="col-2"></div>
//                 <div className="col-8">
//                   <button
//                     className="btn btn-dark text-white"
//                     type="button"
//                     onClick={playAgain}
//                   >
//                     Play again!
//                   </button>
//                 </div>
//                 <div className="col-2"></div>
//               </div>
//               <div className="row text-center">
//                 <NavLink
//                   to="/Activities"
//                   className="text-dark"
//                   style={{ textDecoration: "none" }}
//                 >
//                   Quit
//                 </NavLink>
//               </div>
//             </div>
//             <div className="col-1 p-0"></div>
//           </div>
//           <div className="row bg-dark m-0 p-0" style={{ height: "40px" }}></div>
//         </dialog>
//       </div>
//     </div>
//   );
// }

// export default MemoryGameBoard;

// import React from "react";
// import MemoryGameCard from "./MemoryGameCard";
// import { useState, useEffect } from "react";
// import { GameStatus } from "./gameStatus";
// import confetti from "canvas-confetti";
// import { NavLink } from "react-router-dom";

// // found a match confetti
// var colors = [
//   "#70bf54",
//   "#00854a",
//   "#e49f15",
//   "#feee00",
//   "#f48a6c",
//   "#ea5a42",
//   "#6ccada",
//   "#1e92a2",
// ];

// function matchConfetti() {
//   confetti({
//     particleCount: 100,
//     angle: 60,
//     spread: 55,
//     origin: { x: 0 },
//     colors: colors,
//   });
//   confetti({
//     particleCount: 100,
//     angle: 120,
//     spread: 55,
//     origin: { x: 1 },
//     colors: colors,
//   });
// }

// // game winning confetti
// var duration;
// var animationEnd;
// var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

// function randomInRange(min, max) {
//   return Math.random() * (max - min) + min;
// }

// function gameConfetti() {
//   var interval = setInterval(function () {
//     var timeLeft = animationEnd - Date.now();

//     if (timeLeft <= 0) {
//       return clearInterval(interval);
//     }

//     var particleCount = 175 * (timeLeft / duration);
//     // since particles fall down, start a bit higher than random
//     confetti(
//       Object.assign({}, defaults, {
//         particleCount,
//         origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
//       })
//     );
//     confetti(
//       Object.assign({}, defaults, {
//         particleCount,
//         origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
//       })
//     );
//   }, 250);
// }

// function MemoryGameBoard({ skunks }) {
//   const [skunkState, setSkunkState] = useState(skunks);
//   const [gameState, setGameState] = useState([]);
//   const [matches, setMatches] = useState(0);
//   const [time, setTime] = useState(0);
//   const [isRunning, setIsRunning] = useState(false);

//   // useEffect(() => {
//   //   let intervalId;
//   //   if (isRunning) {
//   //     // setting time from 0 to 1 every 10 milisecond using javascript setInterval method
//   //     intervalId = setInterval(() => setTime(time + 1), 10);
//   //   }
//   //   return () => clearInterval(intervalId);
//   // }, [isRunning, time]);

//   const minutes = Math.floor((time % 360000) / 6000);
//   const seconds = Math.floor((time % 6000) / 100);
//   const milliseconds = time % 100;

//   const playAgain = () => window.location.reload(true);

//   // updating skunkState to determine if the front or back of each card is currently displaying
//   async function flipCard(currentSkunk) {
//     const disableFlip = document.getElementById(
//       `card${skunkState[currentSkunk].ind}`
//     );
//     disableFlip.classList.add("tempDisableClick");
//     const nextSkunkState = skunkState.slice();
//     const side =
//       nextSkunkState[currentSkunk].sideOfCard === "back" ? "front" : "back";
//     nextSkunkState[currentSkunk].sideOfCard = side;
//     setSkunkState(nextSkunkState);
//     console.log("flipCard");
//     console.log(skunkState);
//   }

//   // update game status
//   async function updateGameStatus(currentSkunk) {
//     gameState.push(
//       new GameStatus(
//         skunkState[currentSkunk].ind,
//         skunkState[currentSkunk].skunkId,
//         skunkState[currentSkunk].name
//       )
//     );
//     setGameState(gameState);
//     console.log("updateGameStatus");
//     console.log(skunkState);
//   }

//   async function validateGameStatus() {
//     // player matched
//     if (
//       gameState.length === 2 &&
//       gameState[0].skunkId === gameState[1].skunkId
//     ) {
//       //console.log("you found a match");
//       const matchCard1 = document.getElementById(`card${gameState[0].ind}`);
//       const matchCard2 = document.getElementById(`card${gameState[1].ind}`);
//       matchCard1.classList.add("permDisableClick");
//       matchCard2.classList.add("permDisableClick");

//       const nextMatches = matches + 1;
//       setMatches(nextMatches);
//       console.log(nextMatches);

//       matchConfetti();

//       // const matchIndicator = document.getElementById("gameWindow");
//       // await delay(400);
//       // matchIndicator.classList.add("yesMatch");
//       // await delay(700);
//       // matchIndicator.classList.remove("yesMatch");

//       if (nextMatches > 7) {
//         // if (nextMatches === 1) {
//         duration = 15 * 1000;
//         animationEnd = Date.now() + duration;
//         gameConfetti();
//         await delay(3000);
//         document.getElementById("winnerModal").showModal();
//         console.log("winner!");
//       }

//       setGameState([]);
//       skunkState.forEach((skunk) => {
//         if (skunk.skunkId === gameState[0].skunkId) {
//           skunk.matched = true;
//         }
//       });
//       setSkunkState(skunkState);

//       // player mis-matched
//     } else if (
//       gameState.length === 2 &&
//       gameState[0].skunkId !== gameState[1].skunkId
//     ) {
//       //console.log("not a match");
//       skunkState.forEach((skunk) => {
//         if (!skunk.matched) {
//           skunk.sideOfCard = "back";
//         }
//       });
//       const disableCardClick = document.getElementsByClassName("memoryCard");
//       for (var i = 0; i < disableCardClick.length; i++) {
//         disableCardClick.item(i).classList.add("tempDisableClick");
//       }
//       // const allCards = document.getElementById("allCards");
//       // allCards.classList.add("gameBoard");
//       const noMatchIndicator = document.getElementById("gameWindow");
//       await delay(275);
//       noMatchIndicator.classList.add("noMatch");
//       await delay(900);
//       setSkunkState(skunkState);
//       setGameState([]);
//       const enableCardClick = document.getElementsByClassName("memoryCard");
//       for (var i = 0; i < enableCardClick.length; i++) {
//         enableCardClick.item(i).classList.remove("tempDisableClick");
//       }
//       // allCards.classList.remove("gameBoard");
//       noMatchIndicator.classList.remove("noMatch");
//       // only one card flipped
//     } else {
//       //console.log("flip another card");
//     }
//     console.log("validateGameStatus");
//     console.log(skunkState);
//   }

//   function delay(millisec) {
//     return new Promise((resolve) => {
//       setTimeout(() => {
//         resolve("");
//       }, millisec);
//     });
//   }

//   async function cardsNeedReset(currentSkunk) {
//     if (gameState.length === 2) {
//       setGameState([]);
//       setSkunkState(skunkState);
//       console.log("cards reset");
//     }
//     console.log("cardsNeedReset");
//     console.log(skunkState);
//   }

//   async function startTimer() {
//     if (!isRunning) {
//       setIsRunning(true);
//     }
//     console.log("startTimer");
//     console.log(skunkState);
//   }

//   async function handleClick(p) {
//     await startTimer();
//     await cardsNeedReset(p);
//     await flipCard(p);
//     await updateGameStatus(p);
//     //console.log(gameState);
//     await validateGameStatus();
//     // console.log(skunkState);
//   }

//   const skunkCards = [];
//   let i = 0;

//   skunkState.forEach((skunk) => {
//     skunkCards.push(
//       <div className="col-lg-3 col-md-6 col-sm-12 meetCard" key={i}>
//         <MemoryGameCard
//           ind={skunk.ind}
//           skunkId={skunk.skunkId}
//           name={skunk.name}
//           photo={skunk.photo}
//           sideOfCard={skunk.sideOfCard}
//           specialColor={skunk.specialColor}
//           flip={() => handleClick(skunk.ind)}
//         />
//       </div>
//     );
//     i++;
//   });

//   // console.log(skunkState);

//   return (
//     <div>
//       <div className="stopwatch-container">
//         <p className="stopwatch-time">
//           {minutes.toString().padStart(2, "0")}:
//           {seconds.toString().padStart(2, "0")}:
//           {milliseconds.toString().padStart(2, "0")}
//         </p>
//         {/* <div className="stopwatch-buttons">
//           <button className="stopwatch-button" onClick={startAndStop}>
//             {isRunning ? "Stop" : "Start"}
//           </button>
//           <button className="stopwatch-button" onClick={reset}>
//             Reset
//           </button>
//         </div> */}
//       </div>
//       <div id="gameWindow">
//         <div className="container pt-3">
//           <div className="row meetCard gutter-2 gx-3 gy-3">{skunkCards}</div>
//         </div>

//         <dialog id="winnerModal" className="p-0">
//           <div className="row bg-dark m-0 p-0" style={{ height: "40px" }}></div>
//           <div className="row p-0">
//             <div className="col-1 p-0"></div>
//             <div className="col-10 p-0">
//               <div className="text-center mt-2 mb-2">
//                 <p>Awesome job, you got them all!!</p>
//               </div>
//               <div className="row text-center">
//                 <div className="col-2"></div>
//                 <div className="col-8">
//                   <button
//                     className="btn btn-dark text-white"
//                     type="button"
//                     onClick={playAgain}
//                   >
//                     Play again!
//                   </button>
//                 </div>
//                 <div className="col-2"></div>
//               </div>
//               <div className="row text-center">
//                 <NavLink
//                   to="/Activities"
//                   className="text-dark"
//                   style={{ textDecoration: "none" }}
//                 >
//                   Quit
//                 </NavLink>
//               </div>
//             </div>
//             <div className="col-1 p-0"></div>
//           </div>
//           <div className="row bg-dark m-0 p-0" style={{ height: "40px" }}></div>
//         </dialog>
//       </div>
//     </div>
//   );
// }

// export default MemoryGameBoard;

// import React from "react";
// import MemoryGameCard from "./MemoryGameCard";
// import { useState } from "react";
// import { GameStatus } from "./gameStatus";
// import confetti from "canvas-confetti";
// import { NavLink } from "react-router-dom";
// import GameTimer from "./GameTimer";

// // found a match confetti
// var colors = [
//   "#70bf54",
//   "#00854a",
//   "#e49f15",
//   "#feee00",
//   "#f48a6c",
//   "#ea5a42",
//   "#6ccada",
//   "#1e92a2",
// ];

// function matchConfetti() {
//   confetti({
//     particleCount: 100,
//     angle: 60,
//     spread: 55,
//     origin: { x: 0 },
//     colors: colors,
//   });
//   confetti({
//     particleCount: 100,
//     angle: 120,
//     spread: 55,
//     origin: { x: 1 },
//     colors: colors,
//   });
// }

// // game winning confetti
// var duration;
// var animationEnd;
// var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

// function randomInRange(min, max) {
//   return Math.random() * (max - min) + min;
// }

// function gameConfetti() {
//   var interval = setInterval(function () {
//     var timeLeft = animationEnd - Date.now();

//     if (timeLeft <= 0) {
//       return clearInterval(interval);
//     }

//     var particleCount = 175 * (timeLeft / duration);
//     // since particles fall down, start a bit higher than random
//     confetti(
//       Object.assign({}, defaults, {
//         particleCount,
//         origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
//       })
//     );
//     confetti(
//       Object.assign({}, defaults, {
//         particleCount,
//         origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
//       })
//     );
//   }, 250);
// }

// function MemoryGameBoard({ skunks }) {
//   const [skunkState, setSkunkState] = useState(skunks);
//   const [gameState, setGameState] = useState([]);
//   const [matches, setMatches] = useState(0);
//   const [timer, setTimer] = useState(false);
//   const playAgain = () => window.location.reload(true);

//   // updating skunkState to determine if the front or back of each card is currently displaying
//   async function flipCard(currentSkunk) {
//     if (timer === false) {
//       setTimer(true);
//     }
//     const disableFlip = document.getElementById(
//       `card${skunkState[currentSkunk].ind}`
//     );
//     disableFlip.classList.add("tempDisableClick");
//     const nextSkunkState = skunkState.slice();
//     const side =
//       nextSkunkState[currentSkunk].sideOfCard === "back" ? "front" : "back";
//     nextSkunkState[currentSkunk].sideOfCard = side;
//     setSkunkState(nextSkunkState);
//   }

//   // update game status
//   async function updateGameStatus(currentSkunk) {
//     gameState.push(
//       new GameStatus(
//         skunkState[currentSkunk].ind,
//         skunkState[currentSkunk].skunkId,
//         skunkState[currentSkunk].name
//       )
//     );
//     setGameState(gameState);
//   }

//   async function validateGameStatus() {
//     // player matched
//     if (
//       gameState.length === 2 &&
//       gameState[0].skunkId === gameState[1].skunkId
//     ) {
//       //console.log("you found a match");
//       const matchCard1 = document.getElementById(`card${gameState[0].ind}`);
//       const matchCard2 = document.getElementById(`card${gameState[1].ind}`);
//       matchCard1.classList.add("permDisableClick");
//       matchCard2.classList.add("permDisableClick");

//       const nextMatches = matches + 1;
//       setMatches(nextMatches);
//       console.log(nextMatches);

//       matchConfetti();

//       // const matchIndicator = document.getElementById("gameWindow");
//       // await delay(400);
//       // matchIndicator.classList.add("yesMatch");
//       // await delay(700);
//       // matchIndicator.classList.remove("yesMatch");

//       if (nextMatches > 7) {
//         // if (nextMatches === 1) {
//         duration = 15 * 1000;
//         animationEnd = Date.now() + duration;
//         gameConfetti();
//         await delay(3000);
//         document.getElementById("winnerModal").showModal();
//         console.log("winner!");
//       }

//       setGameState([]);
//       skunkState.forEach((skunk) => {
//         if (skunk.skunkId === gameState[0].skunkId) {
//           skunk.matched = true;
//         }
//       });
//       setSkunkState(skunkState);

//       // player mis-matched
//     } else if (
//       gameState.length === 2 &&
//       gameState[0].skunkId !== gameState[1].skunkId
//     ) {
//       //console.log("not a match");
//       skunkState.forEach((skunk) => {
//         if (!skunk.matched) {
//           skunk.sideOfCard = "back";
//         }
//       });
//       const disableCardClick = document.getElementsByClassName("memoryCard");
//       for (var i = 0; i < disableCardClick.length; i++) {
//         disableCardClick.item(i).classList.add("tempDisableClick");
//       }
//       // const allCards = document.getElementById("allCards");
//       // allCards.classList.add("gameBoard");
//       const noMatchIndicator = document.getElementById("gameWindow");
//       await delay(275);
//       noMatchIndicator.classList.add("noMatch");
//       await delay(900);
//       setSkunkState(skunkState);
//       setGameState([]);
//       const enableCardClick = document.getElementsByClassName("memoryCard");
//       for (var i = 0; i < enableCardClick.length; i++) {
//         enableCardClick.item(i).classList.remove("tempDisableClick");
//       }
//       // allCards.classList.remove("gameBoard");
//       noMatchIndicator.classList.remove("noMatch");
//       // only one card flipped
//     } else {
//       //console.log("flip another card");
//     }
//   }

//   function delay(millisec) {
//     return new Promise((resolve) => {
//       setTimeout(() => {
//         resolve("");
//       }, millisec);
//     });
//   }

//   async function cardsNeedReset(currentSkunk) {
//     if (gameState.length === 2) {
//       setGameState([]);
//       setSkunkState(skunkState);
//       console.log("cards reset");
//     }
//   }

//   async function handleClick(p) {
//     await cardsNeedReset(p);
//     await flipCard(p);
//     await updateGameStatus(p);
//     //console.log(gameState);
//     await validateGameStatus();
//   }

//   const skunkCards = [];
//   let i = 0;

//   skunkState.forEach((skunk) => {
//     skunkCards.push(
//       <div className="col-lg-3 col-md-6 col-sm-12 meetCard" key={i}>
//         <MemoryGameCard
//           ind={skunk.ind}
//           skunkId={skunk.skunkId}
//           name={skunk.name}
//           photo={skunk.photo}
//           sideOfCard={skunk.sideOfCard}
//           specialColor={skunk.specialColor}
//           flip={() => handleClick(skunk.ind)}
//         />
//       </div>
//     );
//     i++;
//   });

//   // console.log(skunkState);

//   return (
//     <div>
//       <GameTimer start={timer} />
//       <div id="gameWindow">
//         <div className="container pt-3">
//           <div className="row meetCard gutter-2 gx-3 gy-3">{skunkCards}</div>
//         </div>

//         <dialog id="winnerModal" className="p-0">
//           <div className="row bg-dark m-0 p-0" style={{ height: "40px" }}></div>
//           <div className="row p-0">
//             <div className="col-1 p-0"></div>
//             <div className="col-10 p-0">
//               <div className="text-center mt-2 mb-2">
//                 <p>Awesome job, you got them all!!</p>
//               </div>
//               <div className="row text-center">
//                 {/* <NavLink to="/Memory">Play again</NavLink> */}
//                 <div className="col-2"></div>
//                 <div className="col-8">
//                   <button
//                     className="btn btn-dark text-white"
//                     type="button"
//                     onClick={playAgain}
//                   >
//                     Play again!
//                   </button>
//                 </div>
//                 <div className="col-2"></div>
//               </div>
//               <div className="row text-center">
//                 <NavLink
//                   to="/Activities"
//                   className="text-dark"
//                   style={{ textDecoration: "none" }}
//                 >
//                   Quit
//                 </NavLink>
//               </div>
//             </div>
//             <div className="col-1 p-0"></div>
//           </div>
//           <div className="row bg-dark m-0 p-0" style={{ height: "40px" }}></div>
//         </dialog>
//       </div>
//     </div>
//   );
// }

// export default MemoryGameBoard;
